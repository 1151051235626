import React from 'react';
import '../Assets/italic-primary.css';
import '../Assets/text-box.css'

const hobbies = (props) => {
  return (
    <div>
      <p className="italic-primary">My Hobbies: {props.name}</p>
      <input className="text-box" onChange={props.changed} placeholder="Enter your new hobbies"/>
    </div>
  )
}

export default hobbies;