import React from 'react';
import '../Assets/important-message.css';

const person = (props) => {
  return (
    <div className="important-message">
      <p>I'm {props.name} and I am age {props.age} years old!</p>
    </div>
  )
}

export default person;