import React, { Component } from 'react';
import './App.css';
import Faker from 'faker';
import Person from './Common/Person'
import Hobbies from './Common/Hobbies'
import './Assets/button-primary.css'

class App extends Component {
  state = {
    person: {name: 'Sivvoy', age: 21},
    hobbies: {name: 'Bike Riding, Rock climbing and Surfing'},
    showHobbies: false
  }

  switchNameHandler = () => {
    this.setState( {
      person: {name: Faker.name.findName(), age: Math.floor(Math.random() * 30)}
    } )
  }

  toggleHobbiesHandler = () => {
    const doesShow = this.state.showHobbies;
    this.setState({showHobbies: !doesShow})
  }

  nameChangedHandler = (event) => {
    this.setState({
      hobbies: {name: event.target.value}
    })
  }

  render() {  
    const style = {
      backgroundColor: 'green',
      font: 'inherit',
      border: '1px solid blue',
      padding: '8px',
      cursor: 'pointer'
    };

    return (
      <div className="App">
        <h1>React App</h1>
        <p> Learning React!</p>
        <button className="button-primary" onClick={this.switchNameHandler}>Switch person and display their age</button>
        <div>
          <Person 
            name={this.state.person.name}
            age={this.state.person.age}
          />
          <button style={style} onClick={this.toggleHobbiesHandler}>Toggle Hobbies</button>
          { this.state.showHobbies ?
            <Hobbies
              name={this.state.hobbies.name}
              changed={this.nameChangedHandler}
            /> : null
          }
        </div>
      </div>
    );
  }
}

export default App;
